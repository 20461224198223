import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';
import {
	XLottie,
	XModalAnimation,
	XLottieLoader,
	XCard,
} from './AnalysisLoaderStyle';

import LoaderJson from '../../json/loader.json';
import {
	getAnalysisStatusApi,
	getAnalysisStatusReset,
} from '../../actions/getAnalysisStatusAction';

const AnaylsisLoader = (props) => {
	const {
		getAnalysisStatus,
		getAnalysisStatusState,
		getAnalysisStatusReset,
		analysisId,
		analysisCompleted,
		setAnalysisCompleted,
		setLoading,
		loading,
		isMissingFieldsThere,
		isOpenAnalysis,
		setIsMissingFieldsThere,
	} = props;
	console.log('analysisCompleted in loader', analysisCompleted);

	const [lottieModalOpen, setLottieModalOpen] = useState(true);
	const [statusNumber, setStatusNumber] = useState(0);
	const [statusList, setStatusList] = useState([
		{
			key: 1,
			title: 'Analysing Clinical Summary',
			icon: '/images/loader-icon1.svg',
			class: 'inprocess',
			visible: true,
		},
		{
			key: 2,
			title: 'Mapping ICD 10 Codes',
			icon: '/images/loader-icon2.svg',
			class: '',
			visible: false,
		},
		{
			key: 3,
			title: 'Mapping CPT Codes',
			icon: '/images/loader-icon3.svg',
			class: '',
			visible: false,
		},
		{
			key: 4,
			title: 'Checking Coverage Determination',
			icon: '/images/loader-icon4.svg',
			class: 'active',
			visible: false,
		},
		{
			key: 5,
			title: 'Checking for Missing Inclusions',
			icon: '/images/loader-icon5.svg',
			class: 'active',
			visible: false,
		},
		{
			key: 6,
			title: 'Generating Results',
			icon: '/images/loader-icon6.svg',
			class: 'active',
			visible: false,
		},
	]);

	useEffect(() => {
		setStatusList((oldList) => {
			oldList.forEach((item) => {
				if (item.key == 1 && analysisCompleted == 'error') {
					item.class = 'inprocess';
					item.visible = true;
				} else if (analysisCompleted == 'error') {
					item.class = '';
					item.visible = false;
				} else if (analysisCompleted == 'success') {
					item.class = 'active';
					item.visible = true;
				} else if (
					item.key <= statusNumber &&
					!['success', 'error'].includes(analysisCompleted)
				) {
					item.class = 'active';

					if (!item.visible) item.visible = true;
				} else if (
					item.key == statusNumber + 1 &&
					!['success', 'error'].includes(analysisCompleted)
				) {
					item.class = 'inprocess';

					if (!item.visible) item.visible = true;
				}
			});

			return oldList;
		});
	}, [statusNumber]);

	useEffect(() => {
		let updateStatusEvent;
		if (
			statusNumber <= statusList.length + 1 &&
			!['success', 'error'].includes(analysisCompleted)
		) {
			updateStatusEvent = setInterval(() => {
				getAnalysisStatusReset();
				getAnalysisStatus({ id: analysisId });
			}, 5000);
		}
		return () => {
			if (updateStatusEvent) clearInterval(updateStatusEvent);
		};
	}, [statusNumber]);

	useEffect(() => {
		if (getAnalysisStatusState.apiState == 'success') {
			setStatusNumber(getAnalysisStatusState?.data?.status);
		}
	}, [getAnalysisStatusState.apiState]);

	useEffect(() => {
		if (analysisCompleted === 'success') {
			if (!isMissingFieldsThere) {
				let stepDelay = 1000; // Delay between steps in milliseconds
				let finalDelay = 1000; // Delay after the last step in milliseconds
				let stepIndex = statusNumber;

				const stepInterval = setInterval(() => {
					stepIndex++;
					setStatusNumber(stepIndex);

					if (stepIndex >= statusList.length) {
						clearInterval(stepInterval);
						setTimeout(() => {
							setLoading(false); // Wait after the last step
						}, finalDelay);
					}
					// else {
					// 	console.log('set1');
					// 	setLoading(false);
					// }
				}, stepDelay);
				setLoading(false);
			} else {
				setIsMissingFieldsThere(false);
				console.log('set2');
				setLoading(false); // Stop at the current stage
			}
		} else if (analysisCompleted === 'error') {
			setIsMissingFieldsThere(false);
			console.log('set3');
			setLoading(false);
		} else {
		}

		setIsMissingFieldsThere(false);
		console.log(
			'********************************************************************************************************',
		);
		console.log('set4', analysisCompleted);
		setAnalysisCompleted('');
	}, [analysisCompleted]);

	useEffect(() => {
		setStatusList((oldList) => {
			oldList.forEach((item) => {
				if (item.key == 1) {
					item.class = 'inprocess';
					item.visible = true;
				} else {
					item.class = '';
					item.visible = false;
				}
			});
			return oldList;
		});
	}, [loading]);

	return (
		<XModalAnimation
			//title=" "
			centered
			open={lottieModalOpen}
			onOk={() => setLottieModalOpen(false)}
			onCancel={() => setLottieModalOpen(false)}
			footer={false}
			maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
		>
			<XLottieLoader className="mb-4">
				``
				<XLottie animationData={LoaderJson} loop={true} />
			</XLottieLoader>

			<XCard>
				<ul>
					{statusList &&
						statusList.map((item) =>
							item.visible ? (
								<li className={item.class}>
									<span>
										<img width="15px" src={item.icon} />
									</span>
									{item.title}
								</li>
							) : (
								<></>
							),
						)}
				</ul>
				<div class="laser"></div>
			</XCard>
		</XModalAnimation>
	);
};

const mapStateToProps = (state) => ({
	getAnalysisStatusState: state.getAnalysisStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getAnalysisStatus: (params) => dispatch(getAnalysisStatusApi(params)),
	getAnalysisStatusReset: () => dispatch(getAnalysisStatusReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AnaylsisLoader);
