import axios from 'axios';
import config from '../config';
import { getUserToken } from '../utils/helper';

export const GET_ICD_CODES = 'GET_ICD_CODES';
export const GET_ICD_CODES_SUCCESS = 'GET_ICD_CODES_SUCCESS';
export const GET_ICD_CODES_FAIL = 'GET_ICD_CODES_FAIL';
export const GET_ICD_CODES_RESET = 'GET_ICD_CODES_RESET';

export const getIcdCodes = (params) => {
	return {
		type: GET_ICD_CODES,
		params,
	};
};

export const getIcdCodesSuccess = (response) => {
	return {
		type: GET_ICD_CODES_SUCCESS,
		response,
	};
};

export const getIcdCodesFail = (response) => {
	return {
		type: GET_ICD_CODES_FAIL,
		response,
	};
};

export const getIcdCodesReset = () => {
	return {
		type: GET_ICD_CODES_RESET,
	};
};

export const getIcdCodesApi = (data) => {
	return (dispatch) => {
		dispatch(getIcdCodes());
		axios
			.get(`${config.api.base_url}/icdCodes`, {
				params: data,
				headers: {
					Authorization: 'Bearer ' + getUserToken(),
				},
			})
			.then((response) => {
				const getIcdCodesData = response.data;
				dispatch(getIcdCodesSuccess(getIcdCodesData));
			})
			.catch((error) => {
				const errorMsg = error.message;
				dispatch(getIcdCodesFail(errorMsg));
			});
	};
};
