import React from 'react';
import { connect } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { Row, Col, Input, Spin, notification, Dropdown, Menu } from 'antd';
import moment from 'moment';
import {
	ChatBoxDiv,
	ChatHeader,
	HeaderIcon,
	Heading,
	ChatRight,
	SmallTextRight,
	ChatLeft,
	SmallTextLeft,
	InputDiv,
	SendButton,
	ChatWrapper,
} from './ChatStyle';
import './styles.css';
import {
	getMessagesApi,
	getMessagesReset,
} from '../../actions/getMessagesAction';
import { addMessageApi, addMessageReset } from '../../actions/addMessageAction';
import {
	getChatUsersApi,
	getChatUsersReset,
} from '../../actions/getChatUsersAction';
import { getUserId, getUserName } from '../../utils/helper';

const { TextArea } = Input;

const ChatBox = (props) => {
	let {
		case_id,
		getMessages,
		getMessagesReset,
		getMessagesState,
		addMessage,
		addMessageState,
		addMessageReset,
		getChatUsersState,
		getChatUsers,
		getChatUsersReset,
	} = props;
	const inputRef = useRef(null);
	const dropdownRef = useRef(null);
	const menuRef = useRef([]);
	const chatWrapperRef = useRef(null);
	const debounceTimeout = useRef(null);
	const [message, setMessage] = useState('');
	const [messageList, setMessageList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [showSuggestions, setShowSuggestions] = useState([]);
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const [taggedUsersList, setTaggedUsersList] = useState([]);
	const [highlightedIndex, setHighlightedIndex] = useState(0);
	const [hoveredIndex, setHoveredIndex] = useState(null);

	let login_user_id = getUserId();

	useEffect(() => {
		if (getMessagesState.apiState === 'success') {
			if (getMessagesState.data) {
				if (getMessagesState.data?.message?.length > 0) {
					setMessageList(getMessagesState.data?.message);
					setLoading(false);
				}
			}
		} else if (getMessagesState.apiState === 'error') {
			setLoading(false);
			notification.error({ message: getMessagesState.message });
		}
		getMessagesReset();
	}, [getMessagesState]);

	useEffect(() => {
		if (case_id) {
			setLoading(true);
			getMessages({ case_id });
		}
	}, [case_id]);

	const handleInputChange = (e) => {
		const value = e.target.value;
		setMessage(value);

		const lastWord = value.split(' ').pop();

		if (lastWord.startsWith('@')) {
			const searchQuery = lastWord.slice(1);
			// Clear the previous timeout if it exists
			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
			}
			// Set a new timeout to call the API
			debounceTimeout.current = setTimeout(() => {
				getChatUsers({ search: searchQuery });
			}, 500); // 500ms debounce delay

			// getChatUsers({search:searchQuery});
			setDropdownVisible(true);
		} else {
			setHighlightedIndex(0);
			setDropdownVisible(false);
		}

		// Check if a mention has been removed
		const updatedTaggedUsersList = [...taggedUsersList];
		let updatedMessage = value;

		taggedUsersList.forEach((mention, index) => {
			const mentionName = `@${mention.name}`;
			const mentionIndex = value.indexOf(mentionName);

			if (mentionIndex === -1 && !value.includes(mentionName)) {
				updatedTaggedUsersList.splice(index, 1); // Remove the mention from the array
				// If part of the mention is removed, remove the entire mention
				const truncatedMention = mentionName.slice(0, -1);
				// const mentionRegex = new RegExp(`\\u200B${truncatedMention}\\u200B\\S*`, 'g');
				const mentionRegex = new RegExp(
					`\\s?${truncatedMention}\\S*`,
					'g',
				);
				updatedMessage = updatedMessage
					.replace(mentionRegex, '')
					.trim();
				updatedMessage = `${updatedMessage}${' '}`;
			}
		});

		// Update the message and tagged users list
		setMessage(updatedMessage);
		setTaggedUsersList(updatedTaggedUsersList);
	};

	// Cleanup on component unmount
	useEffect(() => {
		return () => {
			if (debounceTimeout.current) {
				clearTimeout(debounceTimeout.current);
			}
		};
	}, []);

	useEffect(() => {
		if (getChatUsersState.apiState === 'success') {
			setHighlightedIndex(0);
			let all_users_list = getChatUsersState.data;
			const formattedUsers = all_users_list.map((user, index) => ({
				label: user.name, // or the appropriate field for the user's name
				key: user._id, // Use a unique identifier, such as index or user.id
			}));
			setShowSuggestions(formattedUsers);
		} else if (getChatUsersState.apiState === 'error') {
			notification.error({ message: getChatUsersState.message });
		}
		addMessageReset();
	}, [getChatUsersState]);

	useEffect(() => {
		if (addMessageState.apiState === 'success') {
			setTaggedUsersList([]);
			console.log('message add successfully');
			// getMessages({ case_id });
		} else if (addMessageState.apiState === 'error') {
			notification.error({ message: addMessageState.message });
		}
		addMessageReset();
	}, [addMessageState]);

	const sendMessage = () => {
		if (message.trim() !== '') {
			let data = {
				case_id,
				message,
				taggedUsersList,
			};
			const currentTimestamp = new Date(Date.now()).toISOString();
			const user_name = getUserName();
			const messageData = {
				message: message,
				user_id: {
					_id: login_user_id,
					name: user_name,
				},
				created_at: currentTimestamp,
			};
			if (loading) {
				setLoading(false);
			}
			setMessageList((prevMessages) => [
				...prevMessages, // Keep previous messages
				messageData, // Append the new message
			]);
			addMessage(data);
			setMessage('');
		}
	};

	useEffect(() => {
		if (chatWrapperRef.current) {
			chatWrapperRef.current.scrollTop =
				chatWrapperRef.current.scrollHeight;
		}
	}, [messageList]);

	const handleMenuClick = (item) => {
		const selectedName = `@${item.label}`;

		// Remove the last @mention from the message
		const lastAtIndex = message.lastIndexOf('@');
		const lastSpaceIndex = message.lastIndexOf(' ', lastAtIndex);
		const newMessage = `${message.substring(
			0,
			lastSpaceIndex + 1,
		)}${selectedName}${' '}`;

		if (inputRef.current) {
			inputRef.current.focus();
		}
		setMessage(newMessage);
		setHighlightedIndex(0);
		setDropdownVisible(false);
		let obj = {
			id: item.key,
			name: item.label,
		};
		// Push the key of the selected name into the mentions array
		setTaggedUsersList([...taggedUsersList, obj]);
	};

	// Function to scroll the highlighted item into view
	const scrollIntoView = (index) => {
		// Use the querySelector on the dropdown menu after it's rendered
		const dropdownMenu = document.querySelector('.ant-dropdown-menu');
		if (dropdownMenu) {
			const items = dropdownMenu.querySelectorAll(
				'.ant-dropdown-menu-item',
			);
			const item = items[index];
			if (item) {
				item.scrollIntoView({
					behavior: 'smooth',
					block: 'nearest',
				});
			}
		}
	};

	useEffect(() => {
		// Scroll to the top when dropdown opens
		if (!dropdownVisible) {
			setTimeout(() => {
				const dropdownMenu =
					document.querySelector('.ant-dropdown-menu');
				if (dropdownMenu) {
					dropdownMenu.scrollTop = 0; // Reset scroll to top
				}
			}, 0); // Delay to allow the dropdown to render
		}
	}, [dropdownVisible]);

	// Function to handle Enter key press
	const handleKeyPress = (e) => {
		if (dropdownVisible) {
			if (e.key === 'ArrowDown') {
				e.preventDefault();
				setHighlightedIndex((prevIndex) => {
					const newIndex =
						prevIndex < showSuggestions.length - 1
							? prevIndex + 1
							: prevIndex;
					scrollIntoView(newIndex); // Adjust scroll position
					return newIndex;
				});
			} else if (e.key === 'ArrowUp') {
				e.preventDefault();
				setHighlightedIndex((prevIndex) => {
					const newIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
					scrollIntoView(newIndex); // Adjust scroll position
					return newIndex;
				});
			} else if (e.key === 'Enter') {
				if (showSuggestions[highlightedIndex]) {
					e.preventDefault();
					handleMenuClick(showSuggestions[highlightedIndex]);
				}
			}
		} else if (e.key === 'Enter') {
			e.preventDefault();
			sendMessage();
		}
	};

	// Set default selected key to the first item's _id
	const defaultSelectedKeys =
		showSuggestions.length > 0 ? [showSuggestions[0].key] : [];

	const menu = (
		<Menu
			ref={menuRef} // Set the ref to the Menu container
			// selectable
			// defaultSelectedKeys={defaultSelectedKeys} // Set the default selected key
			style={{
				maxHeight: '300px', // Increase height
				width: '200px', // Increase width
				overflowY: 'auto', // Add scroll if content overflows
			}}
		>
			{showSuggestions.map((item, index) => {
				return (
					<Menu.Item
						onClick={() => {
							handleMenuClick(item);
						}}
						key={item.key}
						onMouseEnter={() => setHoveredIndex(index)} // Set hover index on mouse enter
						onMouseLeave={() => setHoveredIndex(null)} // Reset hover index on mouse leave
						tabIndex={0} // Make menu items focusable
						style={{
							backgroundColor:
								highlightedIndex === index ||
								hoveredIndex === index ||
								defaultSelectedKeys === item.key
									? '#e6f7ff'
									: 'white', // Highlight on keyboard or mouse hover
						}}
					>
						{item.label}
					</Menu.Item>
				);
			})}
		</Menu>
	);

	return (
		<ChatBoxDiv>
			<ChatHeader>
				<Row>
					<Col xs={24}>
						<HeaderIcon>
							<img
								style={{ marginLeft: '9px' }}
								src="/images/chat-header-icon.svg"
								alt="header Icon"
							/>
						</HeaderIcon>
						<Heading>Notes</Heading>
					</Col>
				</Row>
			</ChatHeader>
			<Spin spinning={loading} size="large">
				<ChatWrapper ref={chatWrapperRef}>
					{messageList.map((item, index) => {
						return (
							<React.Fragment key={index}>
								{!login_user_id ||
								login_user_id?.toString() ===
									item.user_id?._id?.toString() ? (
									<ChatRight>
										<span
											className="Chat-Right"
											dangerouslySetInnerHTML={{
												__html: item.message,
											}}
										>
											{/* {item.message} */}
										</span>
										<br />
										<SmallTextRight>
											<span style={{ textAlign: 'left' }}>
												{moment(item.created_at).format(
													'DD MMM YYYY HH:mm',
												)}
											</span>
											<span
												style={{ textAlign: 'right' }}
											>
												{item.user_id?.name}
											</span>
										</SmallTextRight>
									</ChatRight>
								) : (
									<>
										<div className="w-100 clearfix"></div>
										<ChatLeft>
											<span
												className="Chat-Left"
												dangerouslySetInnerHTML={{
													__html: item.message,
												}}
											>
												{/* {item.message} */}
											</span>
											<br />
											<SmallTextLeft>
												<span
													style={{
														textAlign: 'left',
													}}
												>
													{moment(
														item.created_at,
													).format(
														'DD MMM YYYY HH:mm',
													)}
												</span>
												<span
													style={{
														textAlign: 'right',
													}}
												>
													{item.user_id?.name}
												</span>
											</SmallTextLeft>
										</ChatLeft>
									</>
								)}
							</React.Fragment>
						);
					})}
				</ChatWrapper>
			</Spin>

			<InputDiv>
				<Row>
					<Col xs={18} sm={18} md={18} lg={18} xl={18}>
						<Dropdown
							open={dropdownVisible}
							overlay={menu}
							ref={dropdownRef}
							placement="topLeft"
							overlayStyle={{
								overflowY: 'auto',
								zIndex: 9999, // Ensure this is higher than -1
							}}
							arrow
						>
							<TextArea
								ref={inputRef}
								value={message}
								onChange={handleInputChange}
								onKeyDown={handleKeyPress}
								placeholder="Enter your message"
								// autoSize = {{minRows:'4',maxRows:'5'}}
								autoSize
							/>
						</Dropdown>
					</Col>
					<Col
						xs={6}
						sm={6}
						md={6}
						lg={6}
						xl={6}
						className="d-flex align-items-end justify-content-center text-center"
					>
						<SendButton onClick={sendMessage} size="large">
							<img src="/images/chat-send-icon.svg" />
						</SendButton>
					</Col>
				</Row>
			</InputDiv>
		</ChatBoxDiv>
	);
};

const mapStateToProps = (state) => ({
	getMessagesState: state.getMessages,
	addMessageState: state.addMessage,
	getChatUsersState: state.getChatUsers,
});

const mapDispatchToProps = (dispatch) => ({
	getMessages: (params) => dispatch(getMessagesApi(params)),
	getMessagesReset: () => dispatch(getMessagesReset()),
	getChatUsers: (params) => dispatch(getChatUsersApi(params)),
	getChatUsersReset: () => dispatch(getChatUsersReset()),
	addMessage: (data) => dispatch(addMessageApi(data)),
	addMessageReset: (params) => dispatch(addMessageReset(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatBox);
