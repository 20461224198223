import React, { useState, useRef, useEffect } from 'react';
import { Menu, Layout, Button, Tooltip } from 'antd';
import { Container } from './SidebarStyle';
import {} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';
import { checkModulePermissions } from '../../utils/helper';
import './SidebarStyle';
import './../../styles/GlobalStyle';
import config from '../../config';

const { Sider } = Layout;

const Sidebar = (props) => {
	const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
	const isMobileView = viewportWidth <= 768;
	const organizationLogo = localStorage.getItem('org_logo');
	const [collapse, setCollapse] = useState(isMobileView);
	const location = useLocation();

	const keys = [
		{
			pathname: '/',
			key: '1',
		},
		{
			pathname: '/case-management',
			key: '2',
		},
		{
			pathname: '/user-role-management',
			key: '3',
		},
		{
			pathname: '/open-analysis',
			key: '4',
		},
		{
			pathname: '/case-analysis',
			key: '5',
		},
		{
			pathname: '/case-filing-management',
			key: '6',
		},
		{
			pathname: '/case-closure',
			key: '7',
		},
		{
			pathname: '/coding-team',
			key: '8',
		},
		{
			pathname: '/md-advisor',
			key: '9',
		},
		{
			pathname: '/feedback',
			key: '10',
		},
		{
			pathname: '/case-literature',
			key: '11',
		},
		{
			pathname: '/insurance-company',
			key: '12',
		},
		{
			pathname: '/hospital',
			key: '13',
		},
		{
			pathname: '/regions',
			key: '14',
		},
		{
			pathname: '/organization',
			key: '15',
		},
		{
			pathname: '/timeline-configurations',
			key: '16',
		},
	];

	const updateViewportWidth = () => {
		setViewportWidth(window.innerWidth);
	};

	const handleCollapse = () => {
		if (isMobileView) {
			setCollapse(!collapse); // Always close the sidebar in mobile view
		}
	};

	useEffect(() => {
		window.addEventListener('resize', updateViewportWidth);
		return () => {
			window.removeEventListener('resize', updateViewportWidth);
		};
	}, []);

	return (
		<>
			<Sider
				width={80}
				breakpoint="lg"
				collapsedWidth="0"
				onBreakpoint={(broken) => {
					props.toggle(broken);
				}}
				collapsed={collapse}
				onCollapse={() => {
					setCollapse(!collapse);
				}}
				style={{
					overflow: 'hidden',
					position: 'fixed',
					height: '100vh',
					left: 0,
					top: 0,
					bottom: 0,
					boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.12)',
					zIndex: 9,
				}}
				onClick={handleCollapse} // Add this line
				className="site-layout-background sidebarScroll customScroll"
			>
				<Container>
					<Menu
						defaultSelectedKeys={[
							keys.filter((item) =>
								location.pathname.includes(item.pathname),
							).length > 0
								? keys.filter((item) =>
										location.pathname.includes(
											item.pathname,
										),
								  )[0].key
								: '1',
						]}
						mode="inline"
						id="menuListitems"
						overflowedIndicator={
							isMobileView ? (
								<MenuOutlined />
							) : (
								<img
									width={20}
									src="/images/logo-icon.svg"
									alt="Dashboard"
								/>
							)
						}
						onCollapse={handleCollapse}
					>
						<Link to={'/'}>
							<div>
								<span className="logo">
									<img
										style={{ width: 40 }}
										src={
											organizationLogo
												? `${config.s3_url}${organizationLogo}`
												: '/images/logo-icon.svg'
										}
										// src={'/images/logo-icon.svg'}
										alt="logo"
									/>
								</span>
								{/*
									<Button type="link" className="sider-toggle" onClick={toggle} >
										<MenuFoldOutlined style={{ color: "red", fontSize: 20 }} />
									</Button>
           						*/}
							</div>
						</Link>
						{checkModulePermissions('dashboard').authorized && (
							<Tooltip title="Dashboard" placement="right">
								<Menu.Item key="1">
									<Link
										to={'/'}
										className={
											location.pathname === '/'
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/dashboard-icon.svg"
											alt="Dashboard"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('case-management')
							.authorized && (
							<Tooltip title="Case Management" placement="right">
								<Menu.Item key="2">
									<Link
										to={'/case-management'}
										className={
											location.pathname ===
												'/case-management' ||
											location.pathname.startsWith(
												'/case/view',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/case-icon.svg"
											alt="Case Management"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('user-management')
							.authorized && (
							<Tooltip
								title="User & Role Management"
								placement="right"
							>
								<Menu.Item key="3">
									<Link
										to={'/user-role-management'}
										className={
											location.pathname ===
												'/user-role-management' ||
											location.pathname.startsWith(
												'/user-role-management/',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/user-role-icon.svg"
											alt="User & Role Management"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('open-analysis').authorized && (
							<Tooltip title="Open Analysis" placement="right">
								<Menu.Item key="4">
									<Link
										to={'/open-analysis'}
										className={
											location.pathname ===
											'/open-analysis'
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/open-analysis-icon.svg"
											alt="open-analysis"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('case-analysis').authorized && (
							<Tooltip title="Case Analysis" placement="right">
								<Menu.Item key="5">
									<Link
										to={'/case-analysis'}
										className={
											location.pathname.startsWith(
												'/case-analysis',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/case-analysis-icon.svg"
											alt="case-analysis"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('case-filing').authorized && (
							<Tooltip title="Case Filing" placement="right">
								<Menu.Item key="6">
									<Link
										to={'/case-filing-management'}
										className={
											location.pathname.startsWith(
												'/case-filing-management',
											) ||
											location.pathname.startsWith(
												'/case/filings',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/case-filing-icon.svg"
											alt="case_filing"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('ma-plan-appeals-processing')
							.authorized && (
							<Tooltip
								title="MA Plan Appeals Processing"
								placement="right"
							>
								<Menu.Item key="6">
									<Link
										to={'/standard-appeal-tat'}
										className={
											location.pathname.startsWith(
												'/standard-appeal-tat',
											) ||
											location.pathname.startsWith(
												'/case/ma',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/ma-appeal-processing-icon.svg"
											alt="case_filing"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('case-closure').authorized && (
							<Tooltip title="Case Closure" placement="right">
								<Menu.Item key="7">
									<Link
										to={'/case-closure'}
										className={
											location.pathname.startsWith(
												'/case-closure',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/case-closure-icon.svg"
											alt="case-closure"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('coding-team').authorized && (
							<Tooltip title="Coding Team" placement="right">
								<Menu.Item key="7">
									<Link
										to={'/coding-team'}
										className={
											location.pathname.startsWith(
												'/coding-team',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/code.png"
											alt="coding-team"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('md-advisor').authorized && (
							<Tooltip title="MD Advisor" placement="right">
								<Menu.Item key="7">
									<Link
										to={'/md-advisor'}
										className={
											location.pathname.startsWith(
												'/md-advisor',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/doctor.png"
											alt="md-advisor"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('appeal-letter-assignment')
							.authorized && (
							<Tooltip
								title="Assign Appeal Letter"
								placement="right"
							>
								<Menu.Item key="7">
									<Link
										to={'/appeal-letter-assignment'}
										className={
											location.pathname.startsWith(
												'/appeal-letter-assignment',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/paper.png"
											alt="appeal-letter-assignment"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('feedback-analysis')
							.authorized && (
							<Tooltip title="Feedback" placement="right">
								<Menu.Item key="8">
									<Link
										to={'/feedback'}
										className={
											location.pathname.startsWith(
												'/feedback',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/feedback-icon.svg"
											alt="feedback"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('appeal-letter').authorized && (
							<Tooltip title="Appeal Letter" placement="right">
								<Menu.Item key="9" className="">
									<Link
										to={'/appeal-letter'}
										className={
											location.pathname.startsWith(
												'/appeal-letter',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/sidebar-appeal-letter-icon.svg"
											alt="appeal-letter"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('insurance-company-management')
							.authorized && (
							<Tooltip
								title="Insurance Company"
								placement="right"
							>
								<Menu.Item key="10" className="">
									<Link
										to={'/insurance-company'}
										className={
											location.pathname.startsWith(
												'/insurance-company',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/insurance-company-icon.svg"
											alt="insurance-company"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('hospital-management')
							.authorized && (
							<Tooltip title="Hospitals" placement="right">
								<Menu.Item key="11" className="">
									<Link
										to={'/hospital'}
										className={
											location.pathname.startsWith(
												'/hospital',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/hospital-icon.svg"
											alt="hospital"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('region-management')
							.authorized && (
							<Tooltip title="Regions" placement="right">
								<Menu.Item key="12" className="">
									<Link
										to={'/regions'}
										className={
											location.pathname.startsWith(
												'/regions',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/land-layer-location.svg"
											alt="regions"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('organization-management')
							.authorized && (
							<Tooltip title="Organizations" placement="right">
								<Menu.Item key="13" className="">
									<Link
										to={'/organization'}
										className={
											location.pathname.startsWith(
												'/organization',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/organization.svg"
											alt="organization"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('payer-configuration')
							.authorized && (
							<Tooltip
								title="Payer Configuration"
								placement="right"
							>
								<Menu.Item key="13" className="">
									<Link
										to={'/payer-configuration'}
										className={
											location.pathname.startsWith(
												'/payer-configuration',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/user-role-icon.svg"
											alt="Payer Configuration"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
						{checkModulePermissions('reimbursements')
							.authorized && (
							<Tooltip title="Reimbursements" placement="right">
								<Menu.Item key="13" className="">
									<Link
										to={'/reimbursements'}
										className={
											location.pathname.startsWith(
												'/reimbursements',
											)
												? 'nav-link-item-active'
												: 'nav-link-item'
										}
									>
										<img
											width={20}
											src="/images/reimbursement.svg"
											alt="Reimbursements"
										/>
									</Link>
								</Menu.Item>
							</Tooltip>
						)}
					</Menu>
				</Container>

				<div class="cover-bar"></div>
			</Sider>
			<div
				className="overlayDrawerClose"
				onClick={() => {
					setCollapse(!collapse);
				}}
			>
				<button className="overlayDrawerCloseBtn btn">&times;</button>
			</div>
		</>
	);
};
export default Sidebar;
